import { NavBar, Image as VanImage } from 'vant';
import { orderPreview } from '../../../api/order.js';
import BackOff from '../../common/BackOff.vue';
export default {
  name: 'Photobook',
  components: {
    BackOff,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      orderId: this.$route.query.orderId,
      orderProductId: this.$route.query.orderProductId,
      backUrl: this.$route.query.backUrl,
      // 图片上传后, 用来存储最后提交商品的值
      pictureInfoList: [],
      coverageWidthTitle: '',
      coverageHeightTitle: '',
      coverageWidth: '',
      coverageHeight: '',
      pictureList: [],
      cusAreaW: '0px',
      cusAreaH: '0px',
      cusAreaX: '0px',
      cusAreaY: '0px',
      // addDisableFlag: true,
      uploadProcessFlag: false,
      pictureHasUploadedCount: 1,
      pictureArrayCount: Number,
      backUrl: '/product/detail?productId=' + this.$route.query.productId,
      addAddressFlag: false,
      // 新增地址弹窗
      userDefaultAddressId: 0,
      // 用户的默认地址id
      selectedIndex: -1,
      // 更换图片时,要标记是哪个index,通过全局变量传递一下
      localStorageSelectNumber: Number,
      // 控制选择本地图片是否展示
      localStorageFlag: false,
      // 本地存储的图片url
      localStoragePictureUrls: [],
      titleTop: '0px',
      titleLeft: '0px',
      uploadFlag: true,
      // 上次移动结束之后的宽高和位置信息
      lastMoveAttr: {
        X: 0,
        // 移动过程中X，Y会发生变化,移动结束之后清空
        Y: 0,
        w: 0,
        // 移动开始的时候记录w，h的初始值，移动结束之后清空，移动过程中保持不变
        h: 0,
        scale: 0 // 移动过程中需要记录
      },
      titlePageFlag: false,
      textPage1Flag: false,
      clientScale: 0,
      // 客户端的缩放比例，客户端画布的宽 / 画布的原始宽
      fileList: [],
      // 图片上传后, 用来存储最后提交商品的值
      pictureInfoList: [],
      coverageWidth: '',
      coverageHeight: '',
      productId: this.$route.query.productId,
      skuId: this.$route.query.skuId,
      // productId: 1,
      // skuId: 1,
      productConfig: {},
      cartItemId: 0,
      userAddress: {
        id: 0
      }
    };
  },
  created: async function () {
    this.getOrderProductPreview();
    // this.putUserImage();
  },
  methods: {
    getOrderProductPreview() {
      const previewParams = {
        orderId: this.orderId,
        orderProductId: this.orderProductId
      };
      orderPreview(previewParams).then(res => {
        // console.log(res.data)
        this.pictureInfoList = res.data.data.customData.orderProduct.productDetail.detail.customize;
        this.pictureList = this.pictureInfoList;
        this.coverageWidth = '60vw';
        // eslint-disable-next-line no-eval
        this.coverageHeight = eval(60 / this.pictureInfoList[0].coverImgs[0].coverImgWidth * this.pictureInfoList[0].coverImgs[0].coverImgHeight) + 'vw';

        // console.log("===========finish=========")
        const screenWidth = window.innerWidth;
        // 获取手机上和实际的比例,用作缩放
        // 正文比例
        console.log('screenWidth = ' + screenWidth);
        this.clientScale = screenWidth * 0.6 / this.pictureInfoList[0].coverImgs[0].coverImgWidth;
        console.log('this.clientScale = ' + this.clientScale);
        this.cusAreaW = this.pictureInfoList[0].coverImgs[0].cusArea.w * this.clientScale + 'px';
        this.cusAreaH = this.pictureInfoList[0].coverImgs[0].cusArea.h * this.clientScale + 'px';
        this.cusAreaY = this.pictureInfoList[0].coverImgs[0].cusArea.y * this.clientScale + 'px';
        this.cusAreaX = this.pictureInfoList[0].coverImgs[0].cusArea.x * this.clientScale + 'px';
      });
    }
  }
};